<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        <v-img style="max-width: 400px" src="/img/assignments/pendulum3.png" />
      </p>

      <p class="mb-2">
        Suppose you wanted to do an experiment to find out if changing the weight on the end of the
        string changed the amount of the time the pendulum takes to swing back and forth. Which
        pendulums would you use for the experiment?
      </p>
      <p class="mb-4">
        <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-3">
          <v-radio
            v-for="option in options1"
            :key="option.value"
            :value="option.value"
            class="my-3"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </p>

      <h3><u>Reason</u></h3>
      <p class="mb-2">
        <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mb-0 ml-3">
          <v-radio
            v-for="option in options2"
            :key="option.value"
            :value="option.value"
            class="my-3"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'UPEIToTLItem4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: '1 and 4', value: 'a'},
        {text: '2 and 4', value: 'b'},
        {text: '1 and 3', value: 'c'},
        {text: '2 and 5', value: 'd'},
        {text: 'All', value: 'e'},
      ],
      options2: [
        {
          text: 'The heaviest weight should be compared to the lightest weight.',
          value: 'heavyLight',
        },
        {text: 'All pendulums need to be tested against one another.', value: 'all'},
        {
          text: 'As the number of washers is increased the pendulum should be shortened.',
          value: 'shortPend',
        },
        {
          text: 'The number of washers should be different but the pendulums should be the same length.',
          value: 'diffWashers',
        },
        {
          text: 'The number of washers should be the same but the pendulums should be different lengths.',
          value: 'diffPend',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
